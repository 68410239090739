<template>
  <div :class="onePageParentClass">
    <Header/>
    <transition name="fade">
      <router-view :class="onePageContentClass" class="content-main"></router-view>
    </transition>
    <Footer :class="onePageFooterClass"/>
  </div>
</template>
  
<script>
import Header from './MainLayout/header';
import Footer from './MainLayout/serviceFooter';

export default {
  name: 'ServiceContainer',
  components: {
    Header,
    Footer
  },
  data(){
    return {
      onePagePaths: ['/service/register', '/service/login'],
      onePageParentClass: '',
      onePageContentClass: '',
      onePageFooterClass: '',
    }
  },
  created() {
    // 단순하게 해당 경로로 요청이 들어왔을 때 아래 class를 각 DOM에 추가하는 로직
    const path = this.$route.path;
    this.onePagePaths.forEach(url => {
      if (path === url) {
        this.onePageParentClass = 'one-page-parent';
        this.onePageContentClass = 'one-page-content';
        this.onePageFooterClass = 'one-page-footer';
      }
    });
    
  },
  methods: {

  }
}
</script>
