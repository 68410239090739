var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-dark navbar-custom fixed-top bg-title"},[_c('div',{staticClass:"container px-5"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse menu-fade",attrs:{"id":"navbarResponsive"}},[_c('div',{staticClass:"navbar-nav ms-auto"},[(_vm.isLogin)?_c('div',{staticClass:"c-nav"}):_vm._e(),(_vm.isLogin && _vm.userInfo.user.userClass === 1)?[_vm._m(2)]:_vm._e(),[_vm._m(3),_vm._m(4),_vm._m(5)],(!_vm.isLogin)?[_vm._m(6),_vm._m(7)]:_vm._e(),(_vm.isLogin)?[_c('div',{staticClass:"c-nav noto-sans"},[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-avatar',{attrs:{"variant":"info"}})]},proxy:true}],null,false,2098957585)},[_c('div',{staticClass:"display-base",staticStyle:{"padding":"0.5rem 1.25rem"}},[_c('div',{},[_c('b-avatar',{attrs:{"variant":"info","size":"4.25em"}})],1),_c('div',{staticClass:"ml-3"},[_c('h3',{staticClass:"mb-1",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.userInfo.user.nick))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.userInfo.user.email))]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$bvModal.show('bv-modal-subscribe')}}},[_vm._v("구독관리")])])]),_c('div',{staticClass:"mt-1 mb-1",staticStyle:{"border-bottom":"solid 1px rgba(183, 183, 183, 0.496)"}}),_c('b-dropdown-item',{staticClass:"fs-11",attrs:{"href":"#/order-list"}},[_vm._v("주문내역")]),_c('b-dropdown-item',{staticClass:"fs-11",attrs:{"href":"#/service/member-password-verify"}},[_vm._v("정보수정")]),_c('div',{staticClass:"mt-1 mb-1",staticStyle:{"border-bottom":"solid 1px rgba(183, 183, 183, 0.496)"}}),_c('b-dropdown-item',{staticClass:"fs-11",attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._v("로그아웃")])],1)],1)]:_vm._e()],2)])]),_c('accountManager')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand white-light",attrs:{"href":"#/"}},[_c('img',{attrs:{"src":require("../../../../public/images/HowCost-title-logo(white).png"),"alt":"/","width":"200px","height":"49px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarResponsive","aria-controls":"navbarResponsive","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link menu-title-color fade-out",attrs:{"href":"#/spec-shop","target":"_self"}},[_vm._v("SpacShop")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link menu-title-color fade-out",attrs:{"href":"#/introduction","target":"_self"}},[_vm._v("제품소개")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link menu-title-color fade-out",attrs:{"href":"#/store","target":"_self"}},[_vm._v("제품구매")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link menu-title-color fade-out",attrs:{"href":"http://xcost.me/","target":"_blank"}},[_vm._v("커뮤니티")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link menu-title-color fade-out",attrs:{"href":"#/service/register","target":"_self"}},[_vm._v("회원가입")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link menu-title-color fade-out",attrs:{"href":"#/service/login","target":"_self"}},[_vm._v("로그인")])])])
}]

export { render, staticRenderFns }